<template>
  <div class="auth-wrapper auth-v1 auth-back px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-link class="brand-logo">
        <!-- logo -->
        <vuexy-logo />
      </b-link>
      <b-card class="mb-0 p-1">

        <b-card-title class="mb-1">
          ¿Olvidaste tu contraseña? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Ingrese su correo y te enviaremos instrucciones para restablecer tu contraseña
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Correo"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Correo"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="juan@ejemplo.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="submitting"
            >
              <b-spinner
                v-if="submitting"
                small
              />
              Enviar instrucciones
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Regresar al inicio
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userEmail: '',
      submitting: false,
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      const self = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          self.submitting = true
          store.dispatch('account/forgotPassword', { email: this.userEmail }).then(
            response => {
              self.submitting = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  text: response.message,
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 10000,
              })
            },
            error => {
              self.submitting = false
              const { response } = error
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertCircleIcon',
                  text: response.data.errors.email[0],
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 10000,
              })
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-back{
  background-image: url('~@/assets/images/pages/login.jpg')!important;
  background-position: center;
  height: 100vh!important;
  width: 100%!important;
}
.auth-v1 {
  .auth-inner {
    &:before {
      background-image: none!important;
    }
  }
}
</style>
